import Vue from "vue";

require('../bootstrap');

window.Vue = require('vue');

Vue.component('navigation', require('./Navigation.vue').default);

const app = new Vue({
    el: '#app'
});
